// App.js - WEB
import React, { Component } from 'react';
import firebase from 'firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTopButton from 'react-scroll-to-top';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import WebRoutes from './WebRoutes';
import { ToastContainer } from 'react-toastify';
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import AdHocReporting2 from "../../blocks/AdHocReporting2/src/AdHocReporting2";
import SplashScreen2 from "../../blocks/SplashScreen2/src/SplashScreen2";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Share2 from "../../blocks/Share2/src/Share2";
import ApiIntegration19 from "../../blocks/ApiIntegration19/src/ApiIntegration19";
import EmailLists from "../../blocks/EmailLists/src/EmailLists";
import Reviews2 from "../../blocks/Reviews2/src/Reviews2";
import AddressManagement2 from "../../blocks/AddressManagement2/src/AddressManagement2";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import LiveChat2 from "../../blocks/LiveChat2/src/LiveChat2";
import ShippingChargeCalculator from "../../blocks/ShippingChargeCalculator/src/ShippingChargeCalculator";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import DiscountsOffers2 from "../../blocks/DiscountsOffers2/src/DiscountsOffers2";
import Sms2 from "../../blocks/Sms2/src/Sms2";
import GoogleLogin14 from "../../blocks/GoogleLogin14/src/GoogleLogin14";
import TargetedFeed from "../../blocks/TargetedFeed/src/TargetedFeed";
import FacebookLogin from "../../blocks/FacebookLogin/src/FacebookLogin";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import CouponCodeGenerator from "../../blocks/CouponCodeGenerator/src/CouponCodeGenerator";
import PhoneLogin from "../../blocks/PhoneLogin/src/PhoneLogin";


const routeMap = {
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
AdHocReporting2:{
 component:AdHocReporting2,
path:"/AdHocReporting2"},
SplashScreen2:{
 component:SplashScreen2,
path:"/SplashScreen2"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Share2:{
 component:Share2,
path:"/Share2"},
ApiIntegration19:{
 component:ApiIntegration19,
path:"/ApiIntegration19"},
EmailLists:{
 component:EmailLists,
path:"/EmailLists"},
Reviews2:{
 component:Reviews2,
path:"/Reviews2"},
AddressManagement2:{
 component:AddressManagement2,
path:"/AddressManagement2"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
LiveChat2:{
 component:LiveChat2,
path:"/LiveChat2"},
ShippingChargeCalculator:{
 component:ShippingChargeCalculator,
path:"/ShippingChargeCalculator"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
DiscountsOffers2:{
 component:DiscountsOffers2,
path:"/DiscountsOffers2"},
Sms2:{
 component:Sms2,
path:"/Sms2"},
GoogleLogin14:{
 component:GoogleLogin14,
path:"/GoogleLogin14"},
TargetedFeed:{
 component:TargetedFeed,
path:"/TargetedFeed"},
FacebookLogin:{
 component:FacebookLogin,
path:"/FacebookLogin"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
CouponCodeGenerator:{
 component:CouponCodeGenerator,
path:"/CouponCodeGenerator"},
PhoneLogin:{
 component:PhoneLogin,
path:"/PhoneLogin"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ loader: true });
  }

  render() {
    return (
      <div style={{ overflow: 'hidden' }}>
        <div>
          <WebRoutes />
          <ScrollToTopButton smooth top={200} />
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default App;